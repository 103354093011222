.dropdown {
    display: inline-flex;
    position: relative;
    vertical-align: top;
  }

  .dropdown-trigger:hover {
    color: var(--primary);
  }

  .dropdown.is-active .dropdown-menu,
  .dropdown.is-hoverable:hover .dropdown-menu {
    display: block;
  }
  
  .dropdown.is-right .dropdown-menu {
    left: auto;
    right: 0;
  }
  
  .dropdown.is-up .dropdown-menu {
    bottom: 100%;
    padding-bottom: 4px;
    padding-top: initial;
    top: auto;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    z-index: 20;
    min-width: 12rem;
    padding-top: 4px;
  }
  
  .dropdown-content {
    color: black;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0.5em 1em -0.125em rgba(hsl(0, 0%, 4%), 0.1), 0 0px 0 1px rgba(hsl(0, 0%, 4%), 0.02);
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
  
  .dropdown-item {
    color: hsl(0, 0%, 29%);
    display: block;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 0.375rem 1rem;
    position: relative;
  }
  
  a.dropdown-item,
  button.dropdown-item {
    padding: 0.75rem;
    text-align: inherit;
    white-space: nowrap;
    width: 100%;
  }
  
  a.dropdown-item:hover,
  button.dropdown-item:hover {
    background-color: hsl(0, 0%, 96%);
    color: hsl(0, 0%, 4%);
  }
  
  .dropdown-item.is-active {
    background-color: hsl(229, 53%,  53%);
    color: findColorInvert(hsl(229, 53%,  53%));
  }
  
  .dropdown-divider {
    background-color: findLightColor(hsl(229, 53%,  53%));
    border: none;
    display: block;
    height: 1px;
    margin: 0.5rem 0;
  }